import isUUID from 'validator/lib/isUUID';

import { getEnvironment, getMailDomainBaseURLs, getNotionDomainBaseURL } from '../../env.utils';

import { AuthSyncMessage, NotionUserIDAuthSyncMessage } from './AuthSync.types';

const AUTH_CHANNEL = 'auth_channel';
const INITIAL_HANDSHAKE = 'initial_handshake';
const AUTH_SYNC_VERSION = 1;
const NOTION_USER_ID_KEY = 'notion_user_id';

export const AuthSync: React.FC = () => {
  const env = getEnvironment(window.location.origin);

  // This is the frontend code that is responsible for syncing the localSession data with the parent window.
  // It subscribes to the auth_channel BroadcastChannel and pushes messages to the parent window.
  // See https://dev.notion.so/notion/Tech-Spec-Consistent-Multi-Product-Account-Switcher-680b628266874729871b7af5770b59f3
  const bc = new BroadcastChannel(AUTH_CHANNEL);
  const messageChannel = new MessageChannel();

  const handleInitialHandshake = (event: MessageEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data, origin } = event;
    if (!getMailDomainBaseURLs().includes(origin) && origin !== getNotionDomainBaseURL()) {
      return;
    }

    if (data === INITIAL_HANDSHAKE) {
      window.parent.postMessage({ channel: AUTH_CHANNEL }, origin, [messageChannel.port2]);
      window.removeEventListener('message', handleInitialHandshake);
    }
  };

  window.addEventListener('message', handleInitialHandshake);

  bc.onmessage = (event) => {
    messageChannel.port1.postMessage(event.data);
  };

  const handleQueryMessage = (event: MessageEvent<AuthSyncMessage>) => {
    if (event.data.name === 'getNotionUserID') {
      const notionUserID = localStorage.getItem(NOTION_USER_ID_KEY);
      messageChannel.port1.postMessage({
        name: 'notionUserID',
        notionUserID: notionUserID && isUUID(notionUserID) ? notionUserID : '',
        version: AUTH_SYNC_VERSION,
        source: 'identity'
      } as NotionUserIDAuthSyncMessage);
    }
  };

  const handleMutationMessage = (event: MessageEvent<AuthSyncMessage>) => {
    if (event.data.name === 'setNotionUserID') {
      const notionUserID = event.data.notionUserID;
      if (!isUUID(notionUserID) && notionUserID !== '') return;
      if (notionUserID) {
        localStorage.setItem(NOTION_USER_ID_KEY, event.data.notionUserID);
      } else if (notionUserID === '') {
        localStorage.removeItem(NOTION_USER_ID_KEY);
      }
    }
  };

  messageChannel.port1.onmessage = (event: MessageEvent<AuthSyncMessage>) => {
    if (env !== 'production') {
      console.log('DEBUG: AuthSync message', event.data);
    }
    if (event.data.type === 'query') {
      // If it's a query, handle it with the query handler
      handleQueryMessage(event);
    } else if (event.data.type === 'mutation') {
      // If it's a mutation, handle it with the mutation handler
      handleMutationMessage(event);
    } else {
      if (env !== 'production') {
        console.log('DEBUG: AuthSync posting message', event.data);
      }
      // For any other type of message, broadcast it to all connected clients
      // using the BroadcastChannel
      bc.postMessage(event.data);
    }
  };

  return null;
};
